/* eslint-disable prettier/prettier */
// src\pages\callback-page.js  
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavBar } from "../components/navigation/desktop/nav-bar";
import { MobileNavBar } from "../components/navigation/mobile/mobile-nav-bar";
import { PageLayout } from "../components/page-layout";

export const CallbackPage = () => {
  const { error, isAuthenticated, isLoading, handleRedirectCallback } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuth = async () => {
      try {
        await handleRedirectCallback();
        // Redirect to the page the user intended to visit or default to the home page
        navigate('/dashboard');  // Change '/dashboard' to your desired default route
      } catch (err) {
        console.error("Error handling redirect callback:", err);
        // Handle errors accordingly
      }
    };

    handleAuth();
  }, [handleRedirectCallback, navigate]);

  if (isLoading) {
    return (
      <PageLayout>
        <div className="content-layout">
          <h1 id="page-title" className="content__title">
            Loading...
          </h1>
          <div className="content__body">
            <p id="page-description">Please wait while we complete the authentication process.</p>
          </div>
        </div>
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <div className="content-layout">
          <h1 id="page-title" className="content__title">
            Authentication Error
          </h1>
          <div className="content__body">
            <p id="page-description">
              <span>{error.message}</span>
            </p>
          </div>
        </div>
      </PageLayout>
    );
  }

  return (
    <div className="page-layout">
      <NavBar />
      <MobileNavBar />
      <div className="page-layout__content">
        {isAuthenticated ? (
          <p>Successfully authenticated. Redirecting...</p>
        ) : (
          <p>Redirecting to login...</p>
        )}
      </div>
    </div>
  );
};

/* eslint-disable prettier/prettier */
// src/pages/dashboard-page.js

import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Retool from "react-retool";
import Modal from "../components/modal"; // Import the Modal component
import GoogleSSO from "../components/GoogleSSO"; // Import Google SSO Component

const DashboardPage = () => {
  useDocumentTitle("Dashboard");

  const { user } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  useEffect(() => {
    // Function to handle messages from the iframe
    const handlePostMessage = (event) => {
      // Ensure the event originates from the expected source
      if (event.origin === "https://tryrepbot.retool.com") {
        const { data } = event;
        if (data && data.triggerModal) {
          setIsModalOpen(true); // Open the modal based on event
        }
      }
    };

    // Listen for messages from iframe
    window.addEventListener("message", handlePostMessage);

    // Cleanup listener when the component is unmounted
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, []);

  // Data to pass to Retool, only sending userEmail
  const retoolData = {
    userEmail: user.email,
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close modal
  };

  return (
    <PageLayout>
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <GoogleSSO /> {/* https://tryrepbot.retool.com/p/dashboard */}
        </Modal>
      )}

      <Retool
        url="https://tryrepbot.retool.com/p/dashboard" 
        width="100%"
        height="800px"
        data={retoolData}
      />
    </PageLayout>
  );
};

export default DashboardPage;
